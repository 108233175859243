import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, from, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.authService.getAccessToken()).pipe(
      switchMap(token => {
        let clonedReq = req;
        if (token) {
          //Add token to request
          clonedReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`),
          });
        }
        return next.handle(clonedReq).pipe(
          catchError(error => {
            if (
              error.status === 401 ||
              error.status === 403 ||
              error.status === 0
            ) {
              // Redirect to error page
              this.router.navigate(['/access-error']);
            }
            return throwError(error);
          })
        );
      })
    );
  }
}
