import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoaderService } from '../../shared/services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private readonly loaderService: LoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('update-calcul')) {
      this.loaderService.setMessage(
        'Rafraichissement du calcul estimé à 1 minute'
      );
      this.loaderService.showLoader();
    } else {
      this.loaderService.setMessage('Chargement en cours...');
      this.loaderService.showLoader();
    }

    return next
      .handle(req)
      .pipe(finalize(() => this.loaderService.hiddenLoader()));
  }
}
