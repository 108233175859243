import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { ModuleService } from '../services/module.service';

export const moduleGuard: CanActivateFn = (route, state) => {
  const moduleService = inject(ModuleService);
  const router = inject(Router); // Injecte le Router pour la redirection
  const moduleName = route.data?.['moduleName'];

  if (moduleName && moduleService.isModuleEnabled(moduleName)) {
    return true;
  }

  // Retourne un UrlTree pour rediriger vers /home en cas d'échec
  return router.parseUrl('/home');
};
